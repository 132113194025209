
.egns-preloader{
    min-width: 100%;
    min-height: 100%;
    position: fixed !important;
    z-index: 999999;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: #fff;
    &::before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100vh ;
        background: #fff;
        z-index: -1;
    }
}
.circle-border{
    width:100%;
    max-width: 140px;
    height:100%;
    max-height: 140px;
    border: 0px solid  rgb(var(--c-primary-600));
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin:auto;
    border-radius:50%;
    box-shadow: 2px 2px 30px rgba(0,0,0,0.1);
    svg {
        fill: rgb(var(--c-primary-600));
        path{
            stroke: rgb(var(--c-primary-600));
        }
    }
    .moving-circle{
        width:10px;
        height:10px;
        background: rgb(var(--c-primary-600));
        border-radius:50%;
        position:absolute;
        top:0; bottom:0; left:0;right:0;
        overflow:hidden;
        margin:auto;
        animation: circle 2s linear infinite;
        &:nth-child(2){
            width:10px;
            height:10px;
            background: rgb(var(--c-primary-600));
            border-radius:50%;
            position:absolute;
            top:0; bottom:0; left:0;right:0;
            overflow:hidden;
            margin:auto;
            animation: circle 2.25s linear infinite;
        }
        &:nth-child(3){
            width:10px;
            height:10px;
            background: rgb(var(--c-primary-600));
            border-radius:50%;
            position:absolute;
            top:0; bottom:0; left:0;right:0;
            overflow:hidden;
            margin:auto;
            animation: circle 2.5s linear infinite;
        }
      }
  }
  @keyframes circle{
    0%{
      transform:rotate(0deg)
                translate(-70px)
                rotate(0deg);
    }
    100%{
      transform:rotate(360deg)
                translate(-70px)
                rotate(-360deg);
    }
  }
    $len: 242.776657104492px;
    $time: 1.6s;
#outline {
  stroke-dasharray: $len*0.01, $len;
  stroke-dashoffset: 0;
  animation: anim $time linear infinite;
}
@keyframes anim {
  12.5% {
    stroke-dasharray: $len*0.14, $len;
    stroke-dashoffset: -$len*0.11;
  }
  43.75% {
    stroke-dasharray: $len*0.35, $len;
    stroke-dashoffset: -$len*0.35;
  }
  100% {
    stroke-dasharray: $len*0.01, $len;
    stroke-dashoffset: -$len*0.99;
  }
}